import {ReactElement, ReactNode, useState} from "react";
import {FormattedMessage} from "react-intl";
import {ClaimableWin} from "components/blackfriday/blackFriday";
import {BlackFridayWheel} from "components/blackfriday/blackfridaywheel/BlackFridayWheel";
import {Modal} from "widgets/Modal";
import {BlackFridayLotterySuccess} from "components/blackfriday/blackfridaylotterysuccess/BlackFridayLotterySuccess";
import {redirectTo} from "../../../lib/utility";
import {useMediaQuery} from "react-responsive";
import {breakpoints, mediaQueries} from "../../../lib/responsive-breakpoints";

import missingBackgroundDesktop from "./missing-background-desktop.jpg";
import missingBackgroundMobile from "./missing-background-mobile.jpg";

import bannerWheelDesktopDE from "./Banner_Gluecksrad_Button_desktop_1140x100px_de.jpg";
import bannerWheelDesktopFR from "./Banner_Gluecksrad_Button_desktop_1140x100px_fr.jpg";
import bannerWheelDesktopIT from "./Banner_Gluecksrad_Button_desktop_1140x100px_it.jpg";

import bannerWheelMobileDE from "./Banner_Gluecksrad_Button_mobile_378x100px_de.jpg";
import bannerWheelMobileFR from "./Banner_Gluecksrad_Button_mobile_378x100px_fr.jpg";
import bannerWheelMobileIT from "./Banner_Gluecksrad_Button_mobile_378x100px_it.jpg";
import {useBlLocale} from "../../bundle-entry/i18n/I18n";
import {BlLocale} from "../../../lib/BlLocale";

export interface BlackFridayCartWheelBannerProps {
    orderId: number
    missingAmount: number | null | undefined
    claimableWin: ClaimableWin | null | undefined
    alwaysHideBanner?: boolean
}

export function BlackFridayCartWheelBanner(props: BlackFridayCartWheelBannerProps): ReactElement | null {
    if (props.missingAmount != null && props.missingAmount > 0 && !props.alwaysHideBanner) {
        return <MissingAmountCartBanner missingAmount={props.missingAmount}/>;
    }
    if (props.missingAmount === 0 && props.claimableWin != null /*&& !props.claimableWin.claimed*/) {
        return (
            <DoLotteryCartBanner
                alwaysHideBanner={props.alwaysHideBanner}
                orderId={props.orderId}
                claimableWin={props.claimableWin}
            />
        ) ;
    }

    return null;
}

function MissingAmountCartBanner(props: { missingAmount: number }): ReactElement {

    const isMobile = !useMediaQuery(mediaQueries.sm);
    let backgroundImage = isMobile ? missingBackgroundMobile : missingBackgroundDesktop;

    return (
        <div id="blackfriday-missing-amount-cart-banner"
             style={{backgroundImage: `url(${backgroundImage}`}}
             className={`tw-w-full tw-bg-white tw-flex tw-items-center tw-mb-10 tw-overflow-hidden
                        md:tw-h-32 tw-bg-no-repeat`}>
            <div className="tw-flex-grow tw-text-white tw-leading-snug tw-ml-12 tw-mr-36 tw-my-8
                            md:tw-text-3xl md:tw-pt-8 md:tw-ml-96 md:tw-pl-8">
                <FormattedMessage
                    id="frontend.blackfriday.lottery.missingAmount"
                    values={{
                        amount: props.missingAmount,
                        b: (chunks: ReactNode) => <strong>{chunks}</strong>
                    }}
                />
            </div>
        </div>
    );
}

interface DoLotteryCartBannerProps {
    orderId: number
    claimableWin: ClaimableWin
    alwaysHideBanner?: boolean
}

function DoLotteryCartBanner(props: DoLotteryCartBannerProps): ReactElement {
    const [isPopupOpen, setPopupOpen] = useState(true);
    const [isClaimed, setIsClaimed] = useState(props.claimableWin.claimed);

    const locale = useBlLocale();

    const closeHandler = () => {
        setPopupOpen(false);
        if (isClaimed) {
            redirectTo("/cart");
        }
    };

    return (
        <>
            <Modal open={isPopupOpen} onClose={closeHandler} isClaimed={isClaimed}>
                {isClaimed ? (
                    <div onClick={closeHandler}>
                        <BlackFridayLotterySuccess
                            prizeName={props.claimableWin.name}
                            prizeImage={props.claimableWin.imageUrl}
                        />
                    </div>
                ) : (
                    <BlackFridayWheel
                        closeHandler={closeHandler}
                        wheelPosition={props.claimableWin.wheelPosition}
                        onPriceClaimed={() => setIsClaimed(true)}
                        orderId={props.orderId}
                    />
                )}
            </Modal>
            {(!isClaimed && !props.alwaysHideBanner) && (
                <div id="blackfriday-do-lottery-banner"
                     className={"tw-cursor-pointer"}
                     onClick={() => setPopupOpen(true)}>
                    <picture>
                        <source media={`(max-width: ${breakpoints.sm})`} srcSet={determineBanner(locale, true)}/>
                        <img src={determineBanner(locale, false)} alt={"banner"} />
                    </picture>
                </div>
            )}
        </>
    );
}

function determineBanner(locale: BlLocale, isMobile: boolean): string {
    switch (locale) {
        case "de_CH":
            return isMobile ? bannerWheelMobileDE : bannerWheelDesktopDE;
        case "fr":
            return isMobile ? bannerWheelMobileFR : bannerWheelDesktopFR;
        case "it":
            return isMobile ? bannerWheelMobileIT : bannerWheelDesktopIT;
    }
}